<template>
  <div class="container visiting-warp flex-column">
    <div class="common-head">
      <img
        :src="require('@/assets/image/personbg.png')"
        alt=""
        class="bg-img"
      />
      <img class="img-title" :src="require('@/assets/image/zfrw.png')" alt="" />
      <div class="head-bottom-warp">
        <div
          class="align-center head-item"
          v-for="(item, index) in list"
          :key="index"
          :class="activIndex == index ? 'blue' : 'black'"
          @click="handleClick(index)"
        >
          <div class="bg-blur"></div>
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
    <div v-if="activIndex === 0" class="panel-warp flexone">
      <ul class="panel-warp-ul">
        <li>
          <div class="panel-item-left bra3 panel-item-block">走访名称 <span class="red">*</span></div>
          <div class="panel-item-right bra3 panel-item-block plr">
            <input v-model="ruleforms.name" class="panel-input" placeholder="请输入" />
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">走访类型  <span class="red">*</span></div>
          <div
            class="panel-item-right bra3 panel-item-block plr edit-sel"
            @click="zflxShow = true"
          >
            <span v-if="ruleforms.type">{{ $dt.changeCodeName('interview_type',  ruleforms.type )}}</span>
            <span class="plh" v-else>请选择</span>
            <van-icon name="arrow-down" class="icon" />
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">一级网格</div>
          <div class="panel-item-right bra3 panel-item-block plr"  @click="handleAddOne">
            {{areaOne || "请选择"}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">二级网格</div>
          <div class="panel-item-right bra3 panel-item-block plr"  @click="wgShowTwo = true;">
            {{areaTwo || "请选择"}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">三级网格</div>
          <div class="panel-item-right bra3 plr panel-item-block"  @click="wgShowThree = true;">
            {{areaThree || "请选择"}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">走访选择</div>
          <div class="panel-item-right bra3">
            <div
              class="panel-item-block plr edit-sel"
              @click="zfxzTopShow = true"
            >
              <span v-if="ruleforms.target">{{ $dt.changeCodeName('interview_target',  ruleforms.target )}}</span>
              <span class="plh" v-else>请选择</span>
              <van-icon name="arrow-down" class="icon" />
            </div>
            <div
              class="panel-item-block plr bt2 edit-sel"
              @click="handleShowLx"
            >
              <span v-if="ruleforms.target_info">{{ ruleforms.target_info }}</span>
              <span class="plh" v-else>请选择</span>
              <van-icon name="arrow-down" class="icon" />
            </div>
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            详细地址
          </div>
          <div class="panel-item-right bra3 plr panel-item-block">
            <input v-model="ruleforms.addr" class="panel-input" placeholder="请输入" />
          </div>
        </li>
        <!-- <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            月走访次数
          </div>
          <div class="panel-item-right bra3 panel-item-block fill">
            <div class="d-f-s">
              <div class="panel-item-block plr br2 flex1">
                <input
                  v-model="forms.a"
                  class="panel-input"
                  placeholder="请输入"
                />
              </div>
              <div class="panel-item-block plr br2 flex1">年走访次数</div>
              <div class="panel-item-block plr flex1">
                <input
                  v-model="forms.a"
                  class="panel-input"
                  placeholder="请输入"
                />
              </div>
            </div>
          </div>
        </li> -->
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            走访说明
          </div>
          <div class="panel-item-right bra3 plr panel-item-block">
            <input v-model="ruleforms.remark" class="panel-input" placeholder="请输入" />
          </div>
        </li>

         <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            <div>
              <div class="t-c">拍照上传</div>
              <div class="f-s10 t-c m-t4">最多支持6张</div>
            </div>
          </div>
          <div class="panel-item-right bra3 panel-item-block plr">
            <van-uploader
              v-model="fileImgList"
              :before-read="handleImagebefread"
              :after-read="modelImgRead"
              multiple
              max-count="6"
            />
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            视频上传
          </div>
          <div class="panel-item-right bra3 panel-item-block plr">
            <van-uploader
              v-model="fileVideoList"
              multiple
              max-count="6"
              class="video-upload"
              :before-read="handlebefread"
              :after-read="modelVideoRead"
              @click-preview="handlePreview"
              accept="video/*"
            >
              <div slot="preview-cover">
                <video
                  v-for="(item, index) in fileVideoList"
                  :key="index"
                  style="width: 120px; height: 120px; object-fit: cover"
                  :src="item.content"
                ></video>
              </div>
            </van-uploader>
          </div>
        </li>
      </ul>
      <div class="warp-btn">
        <van-button type="info" color="rgb(38, 120, 255)" @click="handleSubmit">立即提交</van-button>
      </div>
    </div>
    <template v-else>
      <div class="date-bottom-warp">
        <div class="date-item align-left blue">
          <div class="bg-blur"></div>
          <div class="date-name" @click="show = true">
            <span class="p-l3">{{ date }}</span>
            <span
              ><img :src="require('@/assets/image/path7.png')" class="img-icon"
            /></span>
          </div>
          <div class="date-button">
            <span class="van-button m-r8" @click="handleSearch">查 询</span>
            <span class="van-button" @click="handleReset">重 置</span>
          </div>
        </div>
      </div>
      <div class="flexone">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
          v-if="dataList.length && dataList.length > 0"
          offset="50"
        >
          <div
            class="commonInfo-content"
            v-for="(item, index) in dataList"
            :key="index"
            @click="routerTo(item)"
          >
            <div class="commonInfo-left">
              <div>
                <img
                  :src="require('@/assets/image/path8.png')"
                  alt=""
                  class="path8-img"
                />
              </div>
              <div class="commonInfoInner-right">
                <p class="commonInfo-name">{{ item.name }}</p>
                <p class="commonInfo-date">{{ item.time || "无" }}</p>
              </div>
            </div>
          </div></van-list
        >
        <div class="nothing" v-if="!dataList.length">
          <van-empty description="暂无数据" />
        </div>
      </div>
    </template>
    <van-calendar
      v-model="show"
      type="range"
      color="#3a86ff"
      @confirm="onConfirm"
      :min-date="minDate"
    />
    <!-- 走访类型 -->
    <van-popup v-model="zflxShow" position="bottom">
      <van-picker
        title="选择走访类型"
        show-toolbar
        :columns="interview_type"
        @confirm="onConfirmZflx"
        @cancel="onCancelZflx"
        value-key="text"
      />
    </van-popup>
    <!-- 走访选择上 -->
    <van-popup v-model="zfxzTopShow" position="bottom">
      <van-picker
        title="选择走访选择"
        show-toolbar
        :columns="interview_target"
        @confirm="onConfirmZflxTop"
        @cancel="onCancelZflxTop"
      />
    </van-popup>
    <!-- 走访选择下 -->
    <!-- <van-popup v-model="zfxzBottomShow" position="bottom">
      <van-picker
        title="选择走访对象"
        show-toolbar
        :columns="columnszfxzBottom"
        @confirm="onConfirmZflxBottom"
        @cancel="onCancelZflxBottom"
      />
    </van-popup> -->
    <!-- 视频预览 -->
      <van-dialog
      style="width: 100%; border-radius: 0; height: 200px"
      theme="default"
      v-model="showvideoplay"
      :show-cancel-button="false"
      :show-confirm-button="false"
      closeOnClickOverlay
    >
      <video
        controls
        preload="auto"
        style="width: 100%; height: 200px; object-fit: contain"
        :src="videourl"
        playsinline 
        x5-video-player-type="h5" 
        x5-video-player-fullscreen="true"
        x5-video-orientation="portraint" 
        x-webkit-airplay="true"
        v-if="videourl"
      ></video>
    </van-dialog>
    <!-- 一级网格 -->
    <van-popup v-model="wgShowOne" position="bottom">
      <van-picker
        title="选择一级网格"
        show-toolbar
        :columns="areaTree"
        @confirm="onConfirmWgOne"
        @cancel="onCancel('wgShowOne')"
        value-key="name"
      />
    </van-popup>
    <!-- 二级网格 -->
    <van-popup v-model="wgShowTwo" position="bottom">
      <van-picker
        title="选择二级网格"
        show-toolbar
        :columns="wgListTwo"
        @confirm="onConfirmWgTwo"
       @cancel="onCancel('wgShowTwo')"
        value-key="name"
      />
    </van-popup>
    <!-- 三级网格 -->
    <van-popup v-model="wgShowThree" position="bottom">
      <van-picker
        title="选择三级网格"
        show-toolbar
        :columns="wgListThree"
        @confirm="onConfirmWgThree"
        @cancel="onCancel('wgShowThree')"
        value-key="name"
      />
    </van-popup>

    <!-- 选人选房屋弹窗 -->
    <selectPop :requestUrl="requestUrl" :propItem="{name:ruleforms.target == 1 ? 'hh' : 'name',code:'id'}" :queryParams="queryParams" ref="selectPop" @submit="handleSubmitSel"></selectPop>
  </div>
</template>

<script>
import mixin from "@/mixin/eventMixin";
import { mapState } from "vuex";
import { Toast } from 'vant';
import httpService from "@/plugins/http";
import * as imageConversion from "image-conversion";
export default {
  mixins: [mixin],
  computed: {
    ...mapState(["interview_type","areaTree","interview_target","userInfo"]),
  },
  data() {
    return {
      minDate: new Date(1990, 0, 1),
      interfaceUrl: "/api/interview/get",
      list: ["走访填报", "已走访信息"],
      ruleforms:{
        target_info:""
      }, //  走访数据
      zflxShow: false, // 走访类型
      zfxzTopShow: false, // 走访选择 上
      zfxzBottomShow: false, // 走访选择 下
      columnszfxzBottom: [],
      fileList: [],
      videourl: "",
      fileImgList: [],
      fileVideoList: [],
      showvideoplay: false,
      showArea:false,
      cascaderValue: "",
      fieldNames: {
        text: "name",
        value: "id",
        children: "node",
      },
      areaOne:"",
      areaTwo:"",
      areaThree:"",
      wgShowOne:false,
      wgShowTwo:false,
      wgShowThree:false,
      wgListOne:[], // 1
      wgListTwo:[], // 2
      wgListThree:[], // 3
      requestUrl:"",
      queryParams:{}, // 格外参数
      otherParams: {
        phone: ''
      },
    };
  },
    created() {
    this.otherParams.phone = this.userInfo.loginAcc
  },
  mounted() {
    this.wgListOne = this.areaTree;
  },
  methods: {
    // 一级网格
    handleAddOne() {
      this.wgShowOne = true;
    },
    onConfirmWgOne(item) {
      this.areaOne = item.name;
       this.areaTwo = "";
       this.areaThree = "";
       this.ruleforms.target_info = "";
      this.wgListTwo =item.node ? JSON.parse(JSON.stringify(item.node)) :[];
      this.ruleforms.area_id = item.id;
      this.onCancel('wgShowOne')
    },
    onConfirmWgTwo(item) {
      this.areaTwo = item.name;
        this.areaThree = "";
            this.ruleforms.target_info = "";
      this.wgListThree = item.node? JSON.parse(JSON.stringify(item.node)):[];
      this.ruleforms.area_id = item.id;
      this.onCancel('wgShowTwo')

    },
    onConfirmWgThree(item) {
      this.areaThree = item.name;
      this.ruleforms.area_id = item.id;
      this.ruleforms.target_info = "";
      this.onCancel('wgShowThree')
    },
    onCancel(name) {
      console.log(1111)
      this[name] = false
    },
    async modelImgRead(file) {
      if (file instanceof Array) {
        file.map((v) => {
          // v.status = "uploading";
          // v.message = "上传中...";
          this.fileUpload(v);
        });
      } else {
        // file.status = "uploading";
        // file.message = "上传中...";
        this.fileUpload(file);
      }
    },
    handleImagebefread(file) {
      return new Promise((resolve, reject) => {
        console.log("压缩前", file);
        // Toast(file.size);
        // console.log('压缩前', file) // 压缩到400KB,大于400KB的图片都会进行压缩，小于则不会
        // 500是控制压缩大小的 根据你自己的需要调整 数值越小压缩越小
        imageConversion.compressAccurately(file, 1024).then((res) => {
          res = new File([res], file.name, {
            type: res.type,
            lastModified: Date.now(),
          });
          console.log("压缩后", res);
          resolve(res);
        });
      });
    },
    fileUpload(file) {
      let _file = file;

      _file.status = "uploading";
      _file.message = "上传中...";

      //创建表单
      var avatarData = new FormData();
      avatarData.append("file", file.file); //将图片加入上传表单

      var xhr = new XMLHttpRequest();
      xhr.open("post", "/api/sys/upload_file", true);
      xhr.upload.onprogress = progressFunction; //【上传进度调用方法实现】
      xhr.onload = uploadComplete; //请求完成
      xhr.onerror = uploadFailed; //请求失败
      xhr.onreadystatechange = function () {
        //当readyState变化时执行 （作为上传结果不可靠）
      };
      //上传进度
      function progressFunction(evt) {
        // event.total是需要传输的总字节，event.loaded是已经传输的字节。如果event.lengthComputable不为真，则event.total等于0
        if (evt.lengthComputable) {
          //vant框架这个没多大用处
          let complete = ((evt.loaded / evt.total) * 100) | 0;
          _file.upload_progress = complete;
        }
      }
      //上传成功响应
      function uploadComplete(evt) {
        //服务断接收完文件返回的结果
        if (evt.target.readyState == 4 && evt.target.status == 200) {
          var res = eval("(" + evt.target.responseText + ")");
          // file.url=res.result.url + "/" + res.result.imgs.file;
          _file.path = res.path; //用于表单提交保存到数据库
          _file.status = "done";
          _file.message = "上传成功";
        } else {
          _file.status = "failed";
          _file.message = "上传失败";
        }
        // Toast("上传成功！");
      }
      //上传失败
      function uploadFailed(evt) {
        _file.status = "failed";
        _file.message = "上传失败";
      }
      //开始上传
      xhr.send(avatarData);
    },
    async uploadImg(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      const res = await httpService.post("/api/sys/upload_file", formData);
      if (res.code === 0) {
        if (file instanceof Array) {
          //判断是否是数组
          file.map((v, i) => {
            v.status = "success";
            v.message = "";
            v.path = res[i];
          });
        } else {
          file.status = "success";
          file.message = "";
          file.path = res.path;
        }
      }
    },
    handlebefread(e) {
      if (e.size > 104857600) {
        Toast("选择/录制视频不能超过100M");
        return false;
      }
      return true;
    },
    handlePreview(file) {
      this.videourl = file.content;
      this.showvideoplay = true;
    },
    async modelVideoRead(file) {
     if (file instanceof Array) {
        file.map((v) => {
          // v.status = "uploading";
          // v.message = "上传中...";
          this.fileUpload(v);
        });
      } else {
        // file.status = "uploading";
        // file.message = "上传中...";
        this.fileUpload(file);
      }
    },
    async uploadVideo(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      const res = await httpService.post("/api/sys/upload_file", formData);
      if (res.code === 0) {
        if (file instanceof Array) {
          //判断是否是数组
          file.map((v, i) => {
            v.status = "success";
            v.message = "";
            v.path = res[i];
          });
        } else {
          file.status = "success";
          file.message = "";
          file.path = res.path;
        }
      }
    },
    onConfirmZflx(item) {
      this.ruleforms.type = item.value;
      this.zflxShow = false;
    },
    onCancelZflx() {
      this.zflxpoleShow = false;
    },
    onConfirmZflxTop(item, index) {
      this.ruleforms.target = item.value;
      this.zfxzTopShow = false;
    },
    onCancelZflxTop() {
      this.zfxzTopShow = false;
    },
    onCancelZflxBottom() {
      this.zfxzBottomShow = false;
    },
    routerTo(row) {
      this.$router.push({
        path: "visitingTasksDetail",
        query:{
          id:row.id
        }
      });
    },
    // 初始化数据
    initData() {
      this.fileImgList = [];
      this.fileVideoList = [];
      this.ruleforms = {};
      this.showvideoplay = false;
      this.videourl = "";
      this.areaOne = "";
      this.areaTwo = "";
      this.areaThree = "";
      this.wgListTwo = [];
      this.wgListThree = [];
    },
       // 提交
    async handleSubmit() {
      if(!this.ruleforms.name) {
        Toast("走访名称必填");
        return;
      }
      if(!this.ruleforms.type) {
        Toast("走访类型必填");
        return;
      }
      try {
        this.ruleforms.pic = this.fileImgList
          .map((item) => {
            return item.path;
          })
          .join(",");
        this.ruleforms.video = this.fileVideoList
          .map((item) => {
            return item.path;
          })
          .join(",");
        this.$store.commit("showLoading");
        const { code } = await httpService.post("/api/interview/add", {
          ...this.ruleforms,
          staff: this.userInfo.loginAcc
        });
        if (code === 0) {
          Toast("提交成功");
          this.initData()
        } else {
          this.$store.commit("hideLoading");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("hideLoading");
      }
    },
    handleSubmitSel(item) {
      console.log(item,'222')
      let name = this.ruleforms.target == 1 ? 'hh' : 'name';
      console.log(item[name],'eee')
      this.$set(this.ruleforms,'target_info',item[name])
    },
    handleShowLx() {
      if(!this.ruleforms.target) {
        Toast("请选择走访类型")
        return
      }
      if (this.ruleforms.target == 1) {
        this.requestUrl = "/api/house/get";
      } else {
        this.requestUrl = "/api/people/get";
      }
      this.queryParams.area_id = this.getAreaId(this.areaTree,this.ruleforms.area_id) || []
      this.$nextTick(() => {
        this.$refs.selectPop.init();
      })
    },
    getAreaId(arr,id) {
      let areaIdList = [] //整个id
      function getAllId(data) {
        let ids = []
        data.forEach(item => {
          ids.push(item.id)
          if (Array.isArray(item.node) && item.node.length) {
            ids = ids.concat(getAllId(item.node))
          }
        })
        return ids
      }
      function findNode(arr,id) {
        let node = []
        for (var i = 0; i < arr.length; i++) {
          var item = arr[i]
          if (item.id === id) {
              node = node.concat(item)
              break;
          } else {
              if (Array.isArray(item.node) && item.node.length) {
                 node = node.concat(findNode(item.node, id))
              } else {
                 node = node.concat([])
              }
          }
        }
        return node;
      }
      let curnode = findNode(arr,id);
      areaIdList = getAllId(curnode);
      return areaIdList;
    }
  },
};
</script>

<style lang="less">
.visiting-warp {
  background: rgb(248, 248, 248);
  letter-spacing: 0px;
  position: relative;
  .path8-img {
    margin-left: 16px;
    margin-top: 15px;
    width: 14px;
    height: 16px;
    object-fit: cover;
  }
  .warp-btn {
    display: flex;
    padding: 20px;
    justify-content: center;
    .van-button {
      width: 112px;
    }
  }
}
.dialog-warp {
  height:40vh;
}
</style>
